<template>
  <v-card class="p-1 m-0 my-2 px-2" :color="hexTints(area.cor,0.92)">
    <v-container fluid class="m-0 p-0">
      <v-row no-gutters align="center">
        <v-col cols="10" >
          <v-card flat class="m-0 p-0 pe-3 fs-10pt text-justify" @click="$emit('oi')" :color="hexTints(area.cor,0.92)">
            <p class="m-0 p-0 px-1 border" v-if="itemID != ''" :style="'background-color:'+hexTints(area.cor,0.87)">
              <span class="fs-8pt fw-800">ID:</span>
              <span class="ms-1 fs-8pt fw-600 f-lato">{{itemID}}</span>
            </p>
            {{item}}
          </v-card>
        </v-col>
        <v-col cols="2" class="text-right">
          <v-btn color="" v-if="itemID == ''" class="ps-0 pe-1 fs-8pt f-lato text-nonecase" outlined x-small @click="$refs.promptdialogref.show(item)">
            <i v-i:duo#plus-circle#18 v-cHex="area.cor" class="btn-icon-left ps-1"></i>
            adicionar
          </v-btn>
          <div class="text-center" v-cHex="'#009c07'" v-if="itemID != ''">
            <i v-i:ic#check-circle#24></i>
            <p class="m-0 p-0 fs-8pt">adicionado</p>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <promptdialog ref="promptdialogref" @additem="additem"/>
  </v-card>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import ementaModel from "./ementa-model.js";

export default {
  name: "conteudoitem",
  components: {
    'promptdialog': () => import('./promptdialog.vue'),
  },
  props: {
    area: { default: null, type: Object },
    uc: { default: null, type: Object },
    conteudos: { default: null, type: Object },
    item: { default: "", type: String }
  },
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      itemID: "",
    }
  },
  watch: {
    item() {
      this.build();
    }
  },
  mounted: function() {
    var self = this;
    //self.loading = true;
    //rdb.ref('').on('value',function(snapshot) {
    //  self.algo = snapshot.val();
    //  console.log("self.algo",self.algo);
    //  self.build();
    //});
    this.build();
  },
  methods:{

    build() {
      var self = this;
      var texto = this.item.trim().toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
      for(var key in this.conteudos) {
        var texto2 = this.conteudos[key].texto.trim().toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
        if(texto == texto2) {
          console.log("achei: ",key);
          this.itemID = key;
        }
      }
    },

    additem(item) {
      console.log("confirm",item);
      console.log("this.uc",this.uc);
      console.log("this.area",this.area);

      ementaModel.add({ uc: this.uc, texto: item.trim() }, function() {} );
    }

  },
}
</script>

<style scoped>
</style>
